<template>
    <div class="home">
        <b-img src="images/bild.png" fluid />
        <span v-html="$t('presentation.team')" />
        <b-card-group deck>
            <b-card title="Pneuhage Management GmbH & Co. KG">
                <p class="card-text">
                    <strong>Desiree Bertram</strong> 
                    <br />
                    Sekretärin der Geschäftsführung
                    <br/><br/>
                    <a href="mailto: Desiree.Bertram@pneu.com">Desiree.Bertram@pneu.com</a><br/>
                    <br />
                </p>
            </b-card>
            <b-card title="Pneuhage Management GmbH & Co. KG">
                <p class="card-text">
                    <strong>Reinmar Buchleither</strong>
                    <br />Leiter Unternehmensentwicklung
                    <br/><br/>
                    <a href="mailto: Reinmar.Buchleither@pneu.com">Reinmar.Buchleither@pneu.com</a><br/>
                    <br />
                </p>
            </b-card>
        </b-card-group>
        <br/>
        <br/>
        <br/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'team',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>